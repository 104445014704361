@import "@styles/design-system.scss";

.customEmptyMessage {
  .messageHeading {
    font-family: $lato;
    font-weight: 900;
    font-size: $text-22;
    line-height: 29px;
    text-align: center;
    color: $white;
    margin-bottom: 8px;
  }
  .messageSubText {
    font-family: $open-sans;
    font-size: $text-14;
    line-height: 22px;
    text-align: center;
    color: $white-text-secondary;
    margin: 0;
  }
}
