@import "@styles/design-system.scss";
@import "@styles/helpers.scss";

.inlineMessage {
  display: inline-block;
  font-family: $open-sans;
  font-size: $text-14;
  line-height: 22px;
  .inlineMessageText {
    color: $white;
    margin-left: 4px;
  }
  .inlineMessageIcon {
    svg {
      @extend .solid-medium;
    }
  }
  &.error {
    .inlineMessageIcon {
      color: $error-clr;
    }
    .inlineMessageText {
      color: $error-clr;
    }
  }
  &.info {
    .inlineMessageIcon {
      color: $blue-light;
    }
  }
  &.warning {
    .inlineMessageIcon {
      color: $warning-clr;
    }
  }
}
